import clsx from "clsx";
import jmrv_carbon_legend from "../../../assets/jmrv_carbon_legend.png";

export default function GradientLegend({ text }) {
  return (
    <div className={containerStyle}>
      {text && (
        <div className={textLegendStyle}>
          {text}
        </div>
      )}
      <div
        className={numberContainer}
        style={{ backgroundImage: `url(${jmrv_carbon_legend})` }}
      />
      <div className={numberLegendStyle}>
        0
      </div>
    </div>
  );
};

const containerStyle = clsx("-w-fit h-fit pt-14 justify-center bg-white relative rounded-xl flex flex-col items-center");
const textStyle = clsx("-rotate-90 text-right text-mediumGray text-xs bg-white p-1.5 flex-shrink-0 rounded-xl");
const textLegendStyle = clsx(textStyle, "mb-36 w-32");
const numberLegendStyle = clsx(textStyle, "mt-24");
const numberContainer = clsx("-rotate-90 w-52 h-3.5 rounded-lg bg-cover m-2");
