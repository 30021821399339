import mapboxgl from "mapbox-gl";
import { endpoints, layers, view, geojson, admin } from "../";
import * as constants from "../constants";

const cancelEvent = (e) => {
  return e.originalEvent.cancelMapInteractivityOnEventClick;
};

export const updateRasterLayer = (map, layer, url) => {
  const sourceId = `${layer}-source`;
  const layerId = `${layer}-layer`;

  // when new layer is selected, remove the toggle layer (stable forest)
  if (layer === "selected" && map.getLayer("toggle-layer")) {
    map.removeLayer("toggle-layer");
    map.removeSource("toggle-source");
  }

  if (map.getSource(sourceId)) {
    // If source exists, update its tiles property
    map.getSource(sourceId).setTiles([url]);
  } else {
    // If source does not exist, add it and the corresponding layer
    map.addSource(sourceId, {
      type: "raster",
      tiles: [url],
      tileSize: 256,
    });

    map.addLayer(
      {
        id: layerId,
        type: "raster",
        source: sourceId,
      },
      "country-boundaries-outline-layer"
    ); // Add the layer before "country-boundaries-outline-layer"
  }
};

const addAdmin0Layer = (map, selectedGID, setSelection) => {
  const admin0BaseLayer = "gadm_v410_admin0_global";
  return new Promise((resolve, reject) => {
    try {
      const countryBoundariesSrc = {
        type: "vector",
        url: `mapbox://aashary.${admin0BaseLayer}`,
      };
      const countryBoundariesLayer = {
        id: "country-boundaries-layer",
        type: "fill",
        source: countryBoundariesSrc,
        "source-layer": admin0BaseLayer,
        paint: {
          "fill-color": "transparent",
          "fill-opacity": 1,
        },
        minZoom: 0,
        maxZoom: 11,
      };
      const countryBoundariesOutlineLayer = {
        id: "country-boundaries-outline-layer",
        type: "line",
        source: countryBoundariesSrc,
        "source-layer": admin0BaseLayer,
        paint: {
          "line-color": "black",
          "line-width": 1,
          "line-opacity": 0.3,
        },
        interactive: false, // Make the layer non-clickable
      };

      map.addSource("country-boundaries-src", countryBoundariesSrc);
      map.addLayer(countryBoundariesLayer);
      map.addLayer(countryBoundariesOutlineLayer);

      map.on("click", "country-boundaries-layer", async function (e) {
        try {
          const gid = e.features[0].properties.GID_0;

          setSelection(gid, 0);
        } catch (error) {
          console.log("something went wrong", error);
        }
      });

      resolve(); // Resolve the promise once the layers are added
    } catch (error) {
      reject(error); // Reject the promise if there's an error
    }
  });
};

export const onMapLoad = (map, selectedGID, setSelection) => {
  return new Promise((resolve, reject) => {
    // Add zoom in/zoom out control
    var nav = new mapboxgl.NavigationControl();
    map.addControl(nav, "bottom-right");

    map.on("load", async function () {
      try {
        // carbon layer
        const carbonLayer = {
          id: "satellite-layer",
          type: "raster",
          source: {
            type: "raster",
            tiles: [
              "https://tileserver-biomass-2020-100m.s3.us-west-2.amazonaws.com/tileserver-biomass-2024-10-31-v2/{z}/{x}/{y}.png",
            ],
            tileSize: 256,
          },
        };
        map.addLayer(carbonLayer);
        layers.setLayerOrder(map);

        // Add the admin0 layer
        await addAdmin0Layer(map, selectedGID, setSelection);

        // Handle map errors
        map.on("error", function (e) {
          console.log("Map error:", e.error.message);
        });

        resolve(); // Resolve the promise if everything is successful
      } catch (error) {
        console.error("Error adding admin0 layer or updating raster layer:", error);
        reject(error); // Reject the promise if there is an error
      }
    });
  });
};

export const loadData = async (
  map,
  selectedGID,
  selectedAdmin,
  handleLayerClick,
  updateLoadedState,
  setDisplayText
) => {
  const gid = selectedGID;
  const source = `admin-${selectedAdmin}-src`;
  const layer = `admin-${selectedAdmin}-layer`;

  // Get data and move to coordinates
  const data = await endpoints.getAdmin(gid);
  setDisplayText(data?.name);

  // check if there are any popups open so we don't move the map to admin center
  const popups = document.getElementsByClassName("mapboxgl-popup");
  if (popups.length < 1) {
    if (
      data?.center_longitude &&
      data?.center_latitude &&
      admin.getAdminCodeFromGID(gid) === 0
    ) {
      view.moveToCoordinates(
        map,
        [data.center_longitude, data.center_latitude],
        gid
      );
    } else if (selectedAdmin === admin.getAdminCodeFromGID(gid)) {
      view.moveToGeoJson(map, data?.url);
    }
  }

  // Add GeoJSON source and layers
  await geojson.addGeoJsonSource(map, data?.url, source);
  await layers.addTransparentLayer(map, source, layer);
  await layers.addOutlineLayer(map, source, constants.selectedOutline, "#dff840");

  updateLoadedState(selectedAdmin, selectedGID);

  if (handleLayerClick) {
    map.on("click", layer, handleLayerClick);
  }

  layers.setLayerOrder(map);
};

export const addChildrenLayer = async (
  map,
  adminLevelGID,
  source,
  layer,
  adminLevel,
  handleClick,
  updateChildrenState
) => {
  const url = await endpoints.getAdminChildrenUrl(adminLevelGID);

  await geojson.addGeoJsonSource(map, url, source);
  await layers.addTransparentLayer(map, source, layer);

  updateChildrenState(adminLevel, adminLevelGID);

  if (handleClick) {
    map.on("click", layer, handleClick);
  }

  layers.setLayerOrder(map);
};

export const loadAdmin0Data = async (
  map,
  selectedGID,
  selectedAdmin,
  updateLoadedState,
  updateChildrenState,
  handleClickState,
  setDisplayText = "none"
) => {
  await loadData(
    map,
    selectedGID,
    selectedAdmin,
    handleClickState,
    updateLoadedState,
    setDisplayText
  );
  await addChildrenLayer(
    map,
    selectedGID,
    `${constants.admin0src}-children`,
    `${constants.admin0layer}-children`,
    0,
    handleClickState,
    updateChildrenState
  );
};

export const loadAdmin1Data = async (
  map,
  selectedGID,
  selectedAdmin,
  updateLoadedState,
  updateChildrenState,
  handleClickState,
  setDisplayText
) => {
  await loadData(
    map,
    selectedGID,
    selectedAdmin,
    handleClickState,
    updateLoadedState,
    setDisplayText
  );
  await addChildrenLayer(
    map,
    selectedGID,
    `${constants.admin1src}-children`,
    `${constants.admin1layer}-children`,
    1,
    handleClickState,
    updateChildrenState
  );
};
export const loadAdmin2Data = async (
  map,
  selectedGID,
  selectedAdmin,
  updateLoadedState,
  handleClickState,
  setDisplayText
) => {
  await loadData(
    map,
    selectedGID,
    selectedAdmin,
    handleClickState,
    updateLoadedState,
    setDisplayText
  );
  // Assuming admin level 2 is the deepest level and doesn't have children
};

export const handleChildrenClick = (
  e,
  adminLevel,
  gidRef,
  setSelection,
  map
) => {
  if (cancelEvent(e)) return;

  const gidProp = `GID_${adminLevel}`;
  const nameProp = `NAME_${adminLevel}`;
  const gid = e.features[0].properties[gidProp];
  const sameGid = gidRef === gid;

  if (!sameGid && gidRef && gid) {
    const name = e.features[0].properties[nameProp];
    const country = e.features[0].properties.COUNTRY;
    const clickedCoords = [e.lngLat.lng, e.lngLat.lat];
    if (adminLevel === constants.admin2) {
      const name1 = e.features[0].properties["NAME_1"];
      setSelection(gid, adminLevel);
    } else {
      setSelection(gid, adminLevel);
    }
    view.moveViewToClickedLayer(
      map,
      gidRef,
      e.features[0].geometry.coordinates[0],
      clickedCoords
    );
  }
};
