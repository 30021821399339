import { select } from "d3-selection";
import { axisLeft } from "d3-axis";

const d3 = { select, axisLeft };

export default function getTicksOnYAxis(yScale) {
    // Modify the y-axis to show labels only on the grid lines
    return d3
        .axisLeft(yScale)
        .ticks(5)
        .tickSize(5);
}
