import clsx from "clsx";
import GrandChildItem from "../GrandChildItem";
/*  
    This component will represent the second order of the heirarchy. 
*/
export default function ChildItem({ data, displayText, userFunctions }) {
  const handleSelectItem = userFunctions.handleSelectItem;
  const handleKeyDown = userFunctions.handleKeyDownResultNavigation;

  return (
    <ul role="list" aria-label={displayText}>
      {data.children.map((item) => (
        <li key={item.id} className={childStyle} tabIndex={0}>
          <div
            className={textStyle}
            data-displaytext={`${item.name}`}
            tabIndex={1}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(item.admin, item.id);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e, item.admin, item.id);
            }}
            aria-label={item.name}
            role="button"
          >
            {item.name}
          </div>
          {item?.children?.length > 0 && (
            <GrandChildItem
              data={item.children}
              userFunctions={userFunctions}
            />
          )}
        </li>
      ))}
    </ul>
  );
}

const childStyle = clsx("ml-4 cursor-pointer");
const textStyle = clsx(
  "font-semibold pl-1 text-md search-item hover:bg-gray focus:bg-gray hover:rounded focus:rounded"
);
