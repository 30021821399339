import { format } from "d3-format";

const formatInteger = { format }.format(",.0f");
const formatDecimal = (value, decimalPlaces = 2) => {
  const formatter = format(`,.${decimalPlaces}f`);
  return formatter(value);
};

export default function formatNumber(value, unit) {
  if (unit === "Mha") {
    if (value > 0.9 && value < 10) {
      return formatDecimal(value);
    }

    return value;
  }

  if (unit === "GtCO2e" || unit === "MtCO2e") {
    return formatDecimal(value);
  }
  if (unit === "KtCO2e") {
    return formatDecimal(value, 1);
  }
  if (unit === "tCO2e") {
    return formatInteger(value);
  }

  return value > 1 ? formatInteger(value) : value;
}
