import { useEffect } from "react";
import { useCurrentChart } from "../../../../../../../../contexts";

export default function ChartFilter({ children, chartType = "line" }) {
  const { currentChart } = useCurrentChart();
  const data = currentChart.chartData[currentChart.category];
  const xAxisLimits = currentChart.xAxisLimits;

  function updateXAxis(data, xAxisLimits) {
    if (data && xAxisLimits) {
      const minX = xAxisLimits[0];
      const maxX = xAxisLimits[1];

      return data.map((series) => {
        // series.values will only exist for the line chart
        // this functionality will need to be revised for barchart filtering
        if (series.values && chartType === "line") {
          const filteredValues = series.values.filter(
            (d) => d.year >= minX && d.year <= maxX
          );
          return { ...series, values: filteredValues };
        } else {
          return { values: [2020, 2021] };
        }
      });
    }
  }

  useEffect(() => {
    if (data && xAxisLimits) {
      updateXAxis(data, xAxisLimits);
    }
  }, [xAxisLimits, data]);

  return children(updateXAxis(data, xAxisLimits), xAxisLimits);
}
