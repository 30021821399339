import { height } from "../../constants";
import { scaleLinear } from "d3-scale";

const d3 = { scaleLinear };

export default function getYScale(minYValue = 0, maxYValue) {
    const range = maxYValue - minYValue;
    const adjustedPaddingTop = minYValue >= 0 ? range * 0.04 : 0;
    
    return d3
      .scaleLinear()
      .domain([0, maxYValue + adjustedPaddingTop])
      .range([height, 0]);
  }