import { useEffect, useState } from "react";
import { PrimaryBtn } from "../../../../components/Button";
import { signUp } from "../../../../helpers";

const btn = {
  link: "",
  className: "w-full",
};

function validEmail(email) {
  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return email?.match(isValidEmail);
}

export default function Subscribe() {
  const [email, setEmail] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [displayMsg, setDisplayMsg] = useState();
  const [checkbox, setCheckbox] = useState(false);

  const onSetEmail = (e) => {
    const input = e.target.value;
    const valid = validEmail(input);

    setEmail(input);

    if (valid && checkbox) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  const onCheckbox = () => {
    const valid = validEmail(email);
    if (valid && !checkbox) {
      setEnabled(true);
    }

    if (checkbox) {
      setEnabled(false);
    }

    setCheckbox(!checkbox);
  };

  const onClick = (e) => signUp(e, email, setDisplayMsg);

  useEffect(() => {
    if (displayMsg) {
      setTimeout(() => {
        setEmail("");
        setDisplayMsg(null);
      }, 7000);
    }
  }, [displayMsg]);

  const btnProps = { ...btn, onClick, enabled };

  return (
    <div className="m-8 flex h-auto flex-col items-center justify-center">
      <div className="relative flex w-full flex-col">
        <div className="absolute top-0 -mt-8 mb-4 flex h-[50px] flex-col font-bold text-white">
          {displayMsg && (
            <>
              <div className="rounded-lg bg-green px-2 py-1">{displayMsg}</div>
              <div className={displayMsgStyle}></div>
            </>
          )}
        </div>
        <input
          required
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
          className={inputStyle + " required email"}
          placeholder="Enter your email"
          value={email}
          onChange={onSetEmail}
        />
      </div>
      <div className="mb-10 flex flex-nowrap items-start justify-start text-xs">
        <input
          required
          type="checkbox"
          checked={checkbox}
          onChange={onCheckbox}
          className="mr-4"
        />
        <div>
          By submitting your email address, you agree to receive communications
          from CTrees. You can opt-out at any time.
        </div>
      </div>

      <div className="w-full">
        <PrimaryBtn {...btnProps}>send</PrimaryBtn>
      </div>
    </div>
  );
}

const displayMsgStyle =
  "w-[0px] border-t-[10px] border-x-[15px] border-t-green border-x-[transparent] ml-4";
const inputStyle =
  "focus:outline-none focus:ring-b focus:ring-blue w-full border-t-0 border-x-0 border-b-2" +
  " placeholder-slate-400 border-[1px] mb-4 contrast-more:placeholder-slate-500 py-3 px-4";
