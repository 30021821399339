import formatNumber from "../../helpers/formatNumber";

export default function ProgressBar({ title, percentage, value, unit, color }) {
  return (
    <div className="w-full p-2 mt-3 bg-neutral-100 rounded border border-slate-300 flex-col gap-2.5 inline-flex">
      <div className="gap-4 flex">
        <div className="items-center gap-1 flex">
          <div className="text-black text-sm leading-tight">{title}</div>{" "}
        </div>

        {title !== "Total" && value !== 0 ? (
          <div className="grow text-right text-black">{`${percentage}%`}</div>
        ) : (
          <div className="grow text-right text-black"></div>
        )}

        <div className="items-center gap-1 flex">
          <div className="text-black">
            {value === 0 ? 0 : formatNumber(value, unit)}
          </div>
          <div className="text-neutral-500 text-sm">{unit}</div>
        </div>
      </div>
      <div className="w-full h-1 relative">
        {title !== "Total" ? (
          <>
            <div
              className={`h-1 left-0 top-0 absolute`}
              style={{ width: `${percentage}%`, backgroundColor: color }}
            />
            <div
              className="h-1 left-0 top-0 absolute"
              style={{ width: `${100 - percentage}%` }}
            />
          </>
        ) : (
          <>
            <div
              className={`h-1 left-0 top-0 absolute`}
              style={{ width: `100%`, backgroundColor: color }}
            />
          </>
        )}
      </div>
    </div>
  );
}
