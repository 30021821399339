import { margin } from "../../constants";
import formatNumber from "../../helpers/formatNumber";
import { select } from "d3-selection";

const d3 = { select };

export default function addLabelsToYAxis(svg, yAxis, unit) {
  // Modify the y-axis to show labels only on the grid lines
  const yAxisGroup = svg
    .append("g")
    .attr("class", "y-axis")
    .attr("transform", `translate(${margin.left}, 0)`)
    .call(yAxis);

  yAxisGroup
    .selectAll("text")
    .attr("class", "text-black")
    .attr("dy", "0.3em")
    .style("text-anchor", "start")
    .style("font-size", "12px");

  yAxisGroup.selectAll("text").each(function (d) {
    const labelWidth = this.getBBox().width;
    const labelOffset =
      labelWidth > 8 ? -labelWidth - labelWidth / 20 : -labelWidth;
    d3.select(this).attr("dx", labelOffset);
  });
}
