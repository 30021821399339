export const margin = { top: 12, right: 10, bottom: 25, left: 35 };
export const width = 346 - margin.left - margin.right;
export const height = 275 - margin.top - margin.bottom;

const colors = {
    // tailwind.config would need to move into /src to use those colors
    darkGray: "#36373A",
    mediumGray: "#6B6B6B",
    offWhite: "#F5F5F5",
    blueGray: "#D2D8E3",
    chartTotal: "#976F8E",
    chartDeforestation: "#FFAF52",
    chartDegradation: "#EA6AFF",
    chartFire: "#FF6B6B",
    chartForest: "#46CE84",
    chartNonforest: "#DFF841",
    chartWetlands: "#70B4FF",
};
export const emissionLineColors = [
    colors.chartTotal,
    colors.chartDeforestation,
    colors.chartDegradation,
    colors.chartFire,
];
export const otherLineColors = [
    colors.chartTotal,
    colors.chartForest,
    colors.chartNonforest,
];

export const legendItems = {
    emission: {
        emission_total: {
            display: "Total",
            variable: "emission_total",
            color: colors.chartTotal,
        },
        emission_deforestation: {
            display: "Deforestation",
            variable: "emission_deforestation",
            color: colors.chartDeforestation,
        },
        emission_fire: {
            display: "Fire",
            variable: "emission_fire",
            color: colors.chartFire,
        },
        emission_degradation: {
            display: "Degradation",
            variable: "emission_degradation",
            color: colors.chartDegradation,
        },
    },
    area: {
        area_total: {
            display: "Total",
            variable: "area_total",
            color: colors.chartTotal,
        },
        area_forest: {
            display: "Forest",
            variable: "area_forest",
            color: colors.chartForest,
        },
        area_non_forest: {
            display: "Nonforest",
            variable: "area_non_forest",
            color: colors.chartNonforest,
        },
    },
    carbon_stock: {
        carbon_stock_total: {
            display: "Total",
            variable: "carbon_stock_total",
            color: colors.chartTotal,
        },
        carbon_stock_forest: {
            display: "Forest",
            variable: "carbon_stock_forest",
            color: colors.chartForest,
        },
        carbon_stock_non_forest: {
            display: "Nonforest",
            variable: "carbon_stock_non_forest",
            color: colors.chartNonforest,
        },
    },
    removal: {
        removal_total: {
            display: "Total",
            variable: "removal_total",
            color: colors.chartTotal,
        },
        removal_forest: {
            display: "Forest",
            variable: "removal_forest",
            color: colors.chartForest,
        },
        removal_non_forest: {
            display: "Nonforest",
            variable: "removal_non_forest",
            color: colors.chartNonforest,
        },
    },
};

export const orderItems = {
    emission: [
        "emission_total",
        "emission_deforestation",
        "emission_fire",
        "emission_degradation",
    ],
    area: ["area_total", "area_forest", "area_non_forest"],
    carbon_stock: [
        "carbon_stock_total",
        "carbon_stock_forest",
        "carbon_stock_non_forest",
    ],
    removal: ["removal_total", "removal_forest", "removal_non_forest"],
};