import { useEffect, useState, useRef } from "react";
import { select } from "d3-selection";
import { axisBottom } from "d3-axis";
import { Tooltip } from "../components";
import { otherLineColors } from "../constants";
import { width, height, margin } from "../constants";
import {
  getTooltipInitiation,
  initiateChart,
  addUnitLabelOnYAxis,
} from "../helpers";
import {
  getXScale,
  addXAxis,
  getMaxYValue,
  getYScale,
  addChartBackground,
  getTicksOnYAxis,
  addLabelsOnYAxis,
  addGridlinesToYAxis,
  generateBars,
} from "./helpers";
import { useCurrentChart } from "../../../../../../../contexts";

const d3 = {
  select,
  axisBottom,
};

export default function BarChart({ id }) {
  const { currentChart } = useCurrentChart();
  const unit = currentChart.units;
  const data = currentChart.chartData[currentChart.category];
  const xAxisLimits = currentChart.xAxisLimits;

  const processedData = [];
  if (data) {
    // loop through first data set to get years
    data[0].values.forEach((d, i) => {
      const year = d.year;
      const values = {
        year,
        area_total: getBarValue(year, "area_total"),
        area_non_forest: getBarValue(year, "area_non_forest"),
        area_forest: getBarValue(year, "area_forest"),
      };
      processedData.push(values);
    });
  }

  function getBarValue(year, category) {
    let result;
    data.forEach((key) => {
      if (key.name === category) {
        key.values.forEach((value) => {
          if (value.year === year) {
            result = value[unit];
          }
        });
      }
    });
    return result;
  }

  const chartContainerRef = useRef(null);
  const [tooltip, setTooltip] = useState(getTooltipInitiation(unit));

  useEffect(() => {
    if (d3.select(chartContainerRef.current)) {
      // Remove all child elements within the SVG or chart container
      d3.select(chartContainerRef.current).selectAll("*").remove();
    }

    /* INITIALIZE CHART */
    const svg = initiateChart(chartContainerRef, width, height, margin);

    /* X-AXIS and SCALES */
    const years = processedData.map((d) => d.year);
    const xScale = getXScale(years);
    const xAxis = d3.axisBottom(xScale);

    addXAxis(svg, xAxis);

    /* Y-AXIS and SCALES */
    const maxYValue = getMaxYValue(processedData);
    const yScale = getYScale(0, maxYValue);

    // Append a black background rectangle
    addChartBackground(svg, yScale);

    // Modify the y-axis to show labels only on the grid lines
    const yAxis = getTicksOnYAxis(yScale);

    addLabelsOnYAxis(svg, yAxis);

    // unit label on y-axis
    addUnitLabelOnYAxis(svg, unit);

    addGridlinesToYAxis(svg, yScale);

    /* GENERATE BARS */
    generateBars(svg, processedData, xScale, yScale, unit, setTooltip);
  }, [processedData, xAxisLimits]);

  return (
    <>
      <Tooltip {...tooltip} colors={otherLineColors} />
      <div
        ref={chartContainerRef}
        id={id}
        className="panelChart flex items-center justify-center"
      ></div>
    </>
  );
};
