import isNumberInRange from "./isNumberInRange";
import calculateAverage from "./calculateAverage";

export default function getAverageOfCategoryOverTime(data, range, unit) {
    let result = [];
    let sumOfAverages = 0;

    data.forEach((item) => {
        const categoryName = item.name;
        let categoryValues = [];
        // Iterate over each value in the "values" array for the current category
        item.values.forEach((value) => {
            if (isNumberInRange(value.year, range)) {
                categoryValues.push(value[unit]);
            }
        });
        const average = Number(calculateAverage(categoryValues));

        if (!categoryName.includes("total")) {
            sumOfAverages += average;
        }

        const processedValue = {
            percent: 0,
            unit: unit,
            value: average,
        };

        result[categoryName] = processedValue;
    });

    Object.keys(result).map((key) => {
        const val = Number(result[key].value);
        const percent = 100 * (val / sumOfAverages);
        result[key].percent = percent.toFixed(1);
    });

    return result;
}