export function getAdminCodeFromGID(gid) {
  const splitGID = gid.split(".");
  const splitCount = splitGID.length - 1;

  if (gid.length === 3) {
    return 0;
  } else if (splitCount === 1) {
    return 1;
  } else if (splitCount === 2) {
    return 2;
  } else {
    return;
  }
}

// these export functions will help determine if a user clicks a new admin 0 or 1 region (country)
export function getAdmin0Code(gid) {
  return gid.substring(0, 3);
}

export function isSameAdmin0(g1, g2) {
  return getAdmin0Code(g1) === getAdmin0Code(g2);
}

export function getAdmin1Code(gid) {
  return `${gid.split(".")[0]}.${gid.split(".")[1]}_1`;
}

export function isSameAdmin1(g1, g2) {
  if (getAdminCodeFromGID(g1) === 0 || getAdminCodeFromGID(g2) === 0) {
    return false;
  }
  if (getAdminCodeFromGID(g1) === getAdminCodeFromGID(g2)) {
    return getAdmin1Code(g1) === getAdmin1Code(g2);
  }
  return false;
}
