import { useRef, useState, useEffect } from "react";
import SearchInput from "./components/Input";
import ctreesLogo from "../../../assets/ctrees-sm-logo.png";
import SearchOptions from "./components/SearchOptions";
import { endpoints } from "../Mapbox/helpers";
import * as styles from "./styles";

export default function SearchContainer({ setSelection, displayText }) {
  const searchContainerRef = useRef(null);
  const [searchData, setSearchData] = useState([]); // all search items
  const [currentIndex, setCurrentIndex] = useState(-1); // index when they use arrow keys to search
  const [searchTerm, setSearchTerm] = useState(""); // value user is inputing

  // user selects item from search query
  const updateSelection = (adminLevel, gid) => {
    setSelection(gid, adminLevel);
    setCurrentIndex(-1);
  };

  // user selects item from search query
  const handleSelectItem = (gid, adminLevel) => {
    setSearchTerm("");
    updateSelection(gid, adminLevel);
  };

  const handleResetSearchTerm = () => {
    setSearchTerm(""); // Clear search term
  };

  const navigateResults = (key) => {
    const resultItems = document.querySelectorAll(".search-item");
    let currInx = currentIndex;

    if (key === "ArrowDown" && currInx < resultItems.length - 1) {
      currInx = currInx + 1;
      setCurrentIndex(currInx);
    } else if (key === "ArrowUp" && currInx > 0) {
      currInx = currInx - 1;
      setCurrentIndex(currInx);
    }

    if (resultItems[currInx]) {
      resultItems[currInx].focus();
    }
  };

  const handleKeyDownResultNavigation = (e, adminLevel, gid) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      navigateResults(e.key);

      // Disable scrolling during arrow key navigation
      document.getElementById("search-dropdown").style.overflowY = "hidden";
    }
    if (e.key === "Enter") {
      e.stopPropagation();
      handleSelectItem(adminLevel, gid);
    }
  };

  // user typing
  const handleInputChange = (value) => {
    setSearchTerm(value);

    // empty search
    if (value.trim() === "" && searchTerm.trim() !== "") {
      handleResetSearchTerm();
    }
  };

  // user clears input
  const handleInputBlur = () => {
    if (searchTerm.trim() === "" && displayText.trim() === "") {
      handleResetSearchTerm();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setSearchTerm("");
      }
    };

    // close search dropdown when user clicks outside of the search container
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  useEffect(() => {
    const fetchSearchData = async () => {
      try {
        const data = await endpoints.getAdminNameTree();

        if (data) {
          setSearchData(data);
        } else {
          console.error("Failed to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (searchData.length === 0) {
      fetchSearchData();
    } 
  }, []);

  const userFunctions = {
    handleInputChange,
    handleInputBlur,
    handleKeyDownResultNavigation,
    handleSelectItem,
    setSearchTerm,
  };

  return (
    <div className={styles.searchParentContainer} ref={searchContainerRef}>
      <div className="w-full flex gap-5 items-center">
        <div className="w-full flex items-center gap-2">
          <a className="" href="https://ctrees.org" target="_blank">
            <img className="lg:block hidden w-[130px]" src={ctreesLogo} />
          </a>
          <div className="text-black text-[10px] leading-[11px]">
            SELECTED <br />
            AREA
          </div>
          <div className="relative flex items-center w-full">
            <SearchInput
              searchTerm={searchTerm}
              displayText={displayText}
              userFunctions={userFunctions}
            />
            {searchTerm && (
              <SearchOptions
                searchData={searchData}
                searchTerm={searchTerm}
                displayText={displayText}
                userFunctions={userFunctions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
