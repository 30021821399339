import { useRef, useEffect, useState } from "react";
import * as styles from "../../styles";

export default function SearchInput({
  searchTerm,
  displayText,
  userFunctions,
}) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(displayText);
  const [isDoubleClicked, setIsDoubleClicked] = useState(false);

  const handleInputChange = (value) => {
    userFunctions.handleInputChange(value);
    setInputValue(value);
    setIsDoubleClicked(false);
  };

  const handleInputBlur = (e) => {
    userFunctions.handleInputBlur(e);
    if (inputRef.current && inputRef.current !== document.activeElement) {
      setInputValue(displayText);
      setIsDoubleClicked(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      userFunctions.setSearchTerm("");
      setInputValue(displayText);
      if (inputRef.current) {
        inputRef.current.blur();
      }

      setIsDoubleClicked(false);
    }
    userFunctions.handleKeyDownResultNavigation(e);
  };

  // start typing or go back to what user was typing before selecting item
  const handleSingleClick = () => {
    setInputValue(searchTerm.length > 0 ? searchTerm : displayText);
  };

  // selects all text in the input for user for a new search
  const handleDoubleClick = () => {
    // Select all text in the input field
    if (inputRef.current) {
      inputRef.current.select();
      setInputValue("");
      setIsDoubleClicked(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setInputValue(displayText);
        setIsDoubleClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, displayText]);

  useEffect(() => {
    setInputValue(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    setInputValue(displayText);
  }, [displayText]);

  useEffect(() => {
    if (!isDoubleClicked && displayText !== inputValue) {
      setInputValue(inputValue);
    }
  }, [inputValue, isDoubleClicked]);

  const inputProps = {
    type: "text",
    placeholder: "Search location...",
    value: inputValue,
    onChange: (e) => handleInputChange(e.target.value),
    onClick: handleSingleClick,
    onDoubleClick: handleDoubleClick,
    onBlur: handleInputBlur,
    onKeyDown: handleKeyDown,
    ref: inputRef,
    className: styles.input,
  };

  return <input {...inputProps} />;
}
