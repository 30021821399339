import clsx from "clsx";

/*  
    This component will represent the third order of the heirarchy. 
*/
export default function GrandChildItem({ data, userFunctions }) {
  const handleKeyDown = userFunctions.handleKeyDownResultNavigation;
  const handleSelectItem = userFunctions.handleSelectItem;

  return (
    <ul className="ml-8 text-xs">
      {data.map((grandchild) => (
        <li key={grandchild.id} tabIndex={0}>
          <div
            className={textStyle}
            data-displaytext={grandchild.name}
            tabIndex={2}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(grandchild.admin, grandchild.id);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e, grandchild.admin, grandchild.id);
            }}
            aria-label={grandchild.name}
            role="button"
          >
            {grandchild.name}
          </div>
        </li>
      ))}
    </ul>
  );
}

const textStyle = clsx(
  "font-semibold pl-1 text-sm search-item hover:bg-gray focus:bg-gray hover:rounded focus:rounded"
);
