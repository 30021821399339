import { useState } from "react";
import formatNumber from "../../helpers/formatNumber";

export default function Tooltip({ data, unit, x, y, visible, year, colors }) {
  const tooltipStyle = {
    left: x,
    top: y,
  };

  return (
    <div
      style={tooltipStyle}
      className={`absolute opacity-${
        visible ? 1 : 0
      } py-1 bg-black flex-col items-start gap-1`}
    >
      <div className="px-2 bg-black">
        <div className="opacity-90 text-white text-xs">{year}</div>
      </div>
      {data.map((item, index) => (
        <div key={index}>
          <div key={item.name} className="px-2 items-center gap-1 inline-flex">
            <div
              style={{ backgroundColor: colors[index] }}
              className={`w-3 h-3`}
            />{" "}
            <div className="gap-1 flex">
              <div className="opacity-90 text-white text-xs">{formatNumber(item.value, unit)}</div>
              <div className="opacity-90 text-white text-xxs">{unit}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
