import { useState, useEffect } from "react";
import clsx from "clsx";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useCurrentChart } from "../../../../../../../../contexts";

export default function MultiRangeSlider({ id }) {
  const { currentChart } = useCurrentChart();
  const defaultValue = currentChart.chartRange;
  const minRange = defaultValue[0];
  const maxRange = defaultValue[1];
  const rangesExist = minRange && maxRange;
  const step = id === "area" ? 5 : 1;

  const [sliderValue, setSliderValue] = useState(defaultValue);
  const [range, setRange] = useState(defaultValue);

  const handleSliderChange = (newRange) => {
    setRange(newRange);
    currentChart.onFilterChange(id, newRange);
  };

  useEffect(() => {
    handleSliderChange(range);
  }, [range]);

  // when new data is to be applied to the chart
  useEffect(() => {
    setRange(defaultValue);
    setSliderValue(defaultValue);
  }, [defaultValue]);

  const handleStyle = {
    backgroundColor: "white",
    opacity: "1",
    width: "24px",
    height: "24px",
    borderRadius: "100%",
    border: "black solid 2px",
    top: "20%",
  };

  const sliderProps = {
    className: "!pt-2",
    min: minRange,
    max: maxRange,
    range,
    step,
    defaultValue: range,
    value: sliderValue,
    onChange: (value) => {
      handleSliderChange(value);
      setSliderValue(value);
    },
    trackStyle: [{ backgroundColor: "black", height: "4px" }],
    handleStyle: [handleStyle, handleStyle],
  };

  return (
    rangesExist && (
      <div className={sliderContainer}>
        <Slider {...sliderProps} />
        {range.map((r, index) => (
          <div
            key={index}
            className="pt-2.5 absolute"
            style={{
              left: `calc(${((r - minRange) / (maxRange - minRange)) * 100 - 6}% - 4px)`,
            }}
          >
            <div className={sliderYearLabelContainer}>
              <div className="opacity-90 text-white text-xs">{r}</div>
            </div>
          </div>
        ))}
      </div>
    )
  );
}

const sliderContainer = clsx(
  "w-[88%] max-w-[410px] h-10 mx-auto my-4 relative"
);
const sliderYearLabelContainer = clsx(
  "px-2 py-1 bg-black rounded-lg inline-flex"
);
