import { MultiRangeSlider, Legend, ChartFilter } from "./components";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import {
  useCurrentChart,
  useCurrentSelection,
} from "../../../../../../contexts";
import { emissionLineColors, otherLineColors, legendItems } from "./constants";

export default function ChartSection({ type }) {
  const { currentChart } = useCurrentChart();
  const { currentSelection } = useCurrentSelection();
  const category = currentChart.category;
  const xAxisLimits = currentChart.xAxisLimits;
  const units = currentChart.units;
  const data = currentSelection.chartData[category];

  return (
    <>
      <MultiRangeSlider id={category} />
      <Legend legendItems={legendItems[category]} />
      <div className="relative">
        <ChartFilter
          data={data}
          xAxisLimits={xAxisLimits}
          chartType={type}
        >
          {(data, xAxisLimits) =>
            type === "bar" ? (
              <BarChart id={category} />
            ) : (
              <LineChart
                id={category}
                multiLineData={data}
                unit={units}
                xAxisLimits={xAxisLimits}
                lineColors={
                  category === "emission" ? emissionLineColors : otherLineColors
                }
              />
            )
          }
        </ChartFilter>
      </div>
    </>
  );
}
