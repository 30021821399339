import { useEffect, useState, useRef } from "react";
import { select } from "d3-selection";
import { extent } from "d3-array";
import { axisBottom } from "d3-axis";
import { timeYear } from "d3-time";
import { timeFormat } from "d3-time-format";
import { width, height, margin } from "../constants";
import { Tooltip } from "../components";
import { useCurrentChart } from "../../../../../../../contexts";
import {
  getTooltipInitiation,
  initiateChart,
  addUnitLabelOnYAxis,
} from "../helpers";
import {
  addLabelsToXAxis,
  cleanTicksOnXAxis,
  addPositiveBackground,
  addNegativeBackground,
  getTicksOnYAxis,
  addLabelsToYAxis,
  addGridlinesToYAxis,
  addTooltipFunctionality,
  generateLines,
  getYScale,
  getXScale,
} from "./helpers";

const d3 = {
  select,
  extent,
  axisBottom,
  timeYear,
  timeFormat,
};

export default function LineChart({ id, lineColors }) {
  const { currentChart } = useCurrentChart();
  const chartContainerRef = currentChart.chartContainerRef;
  const chartWidth = currentChart.chartWidth;
  const chartHeight = currentChart.chartHeight;
  const unit = currentChart.units;
  const xAxisLimits = currentChart.xAxisLimits;
  const multiLineData = currentChart.chartData[currentChart.category];

  const [tooltip, setTooltip] = useState(getTooltipInitiation(unit));

  const filteredData = multiLineData.map((series) => ({
    ...series,
    values: series.values.filter(
      (d) => d.year >= xAxisLimits[0] && d.year <= xAxisLimits[1]
    ),
  }));

  useEffect(() => {
    if (d3.select(chartContainerRef.current)) {
      // Remove all child elements within the SVG or chart container
      d3.select(chartContainerRef.current).selectAll("*").remove();
    }

    /* INITIALIZE CHART */
    const svg = initiateChart(
      chartContainerRef,
      chartWidth,
      chartHeight,
      margin
    );

    /* X-AXIS and SCALES */
    const numXAxisValues = xAxisLimits[1] - xAxisLimits[0] + 1; // Number of years being charted
    const valuesForCalculations = filteredData[0]?.values;
    const xScale = getXScale(valuesForCalculations);

    const xAxis = d3.axisBottom(xScale);

    // Add first and last label
    addLabelsToXAxis(svg, xAxis, valuesForCalculations.length);

    // Remove ticks from the x-axis except for the first and last
    cleanTicksOnXAxis(svg, valuesForCalculations.length);

    /* Y-AXIS and SCALES */
    const yExtent = d3.extent(
      [].concat(
        ...filteredData.map((series) => series.values.map((d) => d[unit]))
      )
    );
    const minYValue = yExtent[0];
    const maxYValue = yExtent[1];
    const yScale = getYScale(minYValue, maxYValue);

    // Append background before adding yaxis gridlines
    // Append a black background rectangle for positive values
    addPositiveBackground(svg, yScale);
    // Append a grey background rectangle for negative values
    addNegativeBackground(svg, yScale);

    // Modify the y-axis to show labels only on the grid lines
    const yAxis = getTicksOnYAxis(yScale);
    addLabelsToYAxis(svg, yAxis, unit);
    addUnitLabelOnYAxis(svg, unit);
    addGridlinesToYAxis(svg, yScale);

    /* GENERATE LINES */
    generateLines(
      svg,
      filteredData,
      xScale,
      yScale,
      numXAxisValues,
      unit,
      lineColors
    );

    /* CHART ACCESSORIES */
    addTooltipFunctionality(
      svg,
      filteredData,
      xScale,
      yScale,
      setTooltip,
      unit,
      lineColors
    );
  }, [xAxisLimits]);

  return (
    <>
      <Tooltip {...tooltip} colors={lineColors} />
      <div
        ref={chartContainerRef}
        id={id}
        className="panelChart flex items-center justify-center overflow-visible"
      ></div>
    </>
  );
};
