import JMRVMap from "./MapBase";

function App() {
  return (
    <div className="p-0 w-full h-full font-body">
      <JMRVMap />
    </div>
  );
}

export default App;
