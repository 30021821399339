/* END POINTS */
const apiPrefix = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;
const jmrvEndpoint = "/jmrv/data_categorized?gid_value=";
const jmrvPercent = "/jmrv/data_baseline";

export async function getAdmin(gid) {
  const response = await fetch(`${apiPrefix}/gis/admin?gid=${gid}`);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
export async function getAdminChildrenUrl(gid) {
  const response = await fetch(`${apiPrefix}/gis/admin_children?gid=${gid}`);
  if (response.status === 200) {
    const data = await response.json();
    return data.url;
  } else {
    return;
  }
}
export async function getJmrvAdminData(gid) {
  const url = `${apiPrefix}${jmrvEndpoint}${gid}`;
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getJmrvAdminPercent(gid) {
  const response = await fetch(
    `${apiPrefix}${jmrvPercent}?gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getAdminNameTree() {
  const response = await fetch(`${apiPrefix}/gis/admin_name_tree?product_type=JMRV`);

  if (response.status === 200) {
    return response.json();
  } else {
    return { ok: false };
  }
}
