import mapboxgl from "mapbox-gl";
import { fetchGeoJsonData } from "../geojson";

const largerRegions = [
  "USA",
  "CAN",
  "RUS",
  "CHN",
  "BRA",
  "AUS",
  "IND",
  "ARG",
  "KAZ",
  "DZA",
  "GRL",
  "COD",
  "SAU",
  "MEX",
  "IDN",
  "SDN",
  "LBY",
  "IRN",
  "MNG",
  "PER",
];

function calculateZoomLevel(minX, minY, maxX, maxY) {
  const width = maxX - minX;
  const height = maxY - minY;
  const maxDimension = Math.max(width, height);

  if (maxDimension > 10) return 5; // Very large regions
  if (maxDimension > 1) return 8; // Large regions
  if (maxDimension > 0.1) return 10; // Medium regions
  return 12; // Small regions
}

export function calculateCenterCoordinatesAndZoom(coordinates) {
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;

  // Handle the case where coordinates is an array of arrays of arrays (multipolygon)
  const processCoordinates = (coords) => {
    coords.forEach((c) => {
      if (Array.isArray(c[0])) {
        processCoordinates(c); // Recursively process nested arrays
      } else {
        minX = Math.min(minX, c[0]);
        maxX = Math.max(maxX, c[0]);
        minY = Math.min(minY, c[1]);
        maxY = Math.max(maxY, c[1]);
      }
    });
  };

  processCoordinates(coordinates);

  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;
  const zoom = calculateZoomLevel(minX, minY, maxX, maxY);

  return { center: [centerX, centerY], zoom };
}

export function moveToCenterOfLayer(map, center, zoom) {
  if (!center || center.length !== 2) {
    console.error("Invalid center coordinates:", center);
    return;
  }

  const [lng, lat] = center;
  if (isNaN(lng) || isNaN(lat)) {
    console.error("Invalid LngLat object:", center);
    return;
  }

  map.flyTo({
    center: [lng, lat],
    zoom: zoom,
    essential: true,
  });
}

export function moveToCoordinates(map, coordinates, gid) {
  map.flyTo({
    center: coordinates,
    zoom: largerRegions.includes(gid) ? 3 : 5,
    duration: 800,
    essential: true, // prefers-reduced-motion
  });
}

export function moveViewToClickedLayer(
  map,
  gid,
  coordinates,
  clickedCoordinates
) {
  try {
    var bounds = coordinates.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[1]));

    map.fitBounds(bounds, {
      padding: 300,
    });
  } catch (e) {
    moveToCoordinates(map, clickedCoordinates, gid);
    console.log("Error on bound, moving to mouse event click.");
  }
}

export const moveToGeoJson = async (map, url) => {
  const geojson = await fetchGeoJsonData(url);
  const centerCoords = calculateCenterCoordinatesAndZoom(
    geojson.features[0].geometry.coordinates
  );
  moveToCenterOfLayer(map, centerCoords.center, centerCoords.zoom);
};
