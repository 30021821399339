function getAdminLevelBuffer(admin) {
  switch (admin) {
    case 0:
      return 3;
    case 1:
      return 2;
    case 2:
      return 1;
    default:
      return 0;
  }
}

// determines how similar the search term is to the item being searched
function calculateSimilarityScore(item, searchTerm) {
  const name = item.name
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");
  const searchTermLower = searchTerm
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  // prioritize similarity for different admin levels
  const adminLevelBuffer = getAdminLevelBuffer(item.admin);

  if (name === searchTermLower) {
    return 3 + adminLevelBuffer; // Exact match at any level
  } else if (name.startsWith(searchTermLower)) {
    return 2 + adminLevelBuffer; // Partial match at the beginning of the name
  } else if (item.children && item.children.length > 0) {
    // Check similarity of any children
    const childSimilarityScores = item.children.map((child) =>
      calculateSimilarityScore(child, searchTerm)
    );
    const maxChildScore = Math.max(...childSimilarityScores);
    if (maxChildScore > 0) {
      return maxChildScore - 1; // Reduce score slightly to prioritize direct matches
    }
  } else if (item?.children?.children?.length > 0) {
    // Check similarity of any grandchildren
    const grandChildSimilarityScores = item.children.children.map(
      (grandchild) => calculateSimilarityScore(grandchild, searchTerm)
    );
    const maxGrandChildScore = Math.max(...grandChildSimilarityScores);
    if (maxGrandChildScore > 0) {
      return maxGrandChildScore - 2; // Reduce score further to prioritize direct and child matches
    }
  }
  return 0; // No match
}

// filter on every keydown
export default function filterInHierarchy(items, searchTerm) {
  if (!items) {
    return [];
  }

  if (!Array.isArray(items)) {
    return items;
  }

  return items
    .map((item) => ({
      ...item,
      similarityScore: calculateSimilarityScore(item, searchTerm),
      children: filterInHierarchy(item.children, searchTerm),
      grandchildren: filterInHierarchy(
        item.children && item.children.children
          ? item.children.children
          : undefined,
        searchTerm
      ),
    }))
    .sort((a, b) => b.similarityScore - a.similarityScore) // Sort by similarity score, most similar items up top
    .filter((item) => item.similarityScore > 0);
}
