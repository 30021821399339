import isNumberInRange from "./isNumberInRange";

/* AREA DATA */
export const ha = "ha";
export const m2 = "m2";
export const Mha = "Mha";

export const initialAreaUnit = ha;

function isAreaChart(data) {
  const isArea = data[0].values[0].ha ? true : false;
  return isArea;
}

const formatAreaValue = (value, conversionUnit = null) => {
  /**
   * Format area value (Ha) based on magnitude:
   * - If value is greater than 100,000, display in million hectares (Mha).
   * - If value is less than 0.01, display in square meters (m2).
   * - Otherwise, display in hectares (Ha).
   */

  if (
    (value > 1000000 && conversionUnit === null) ||
    conversionUnit === Mha
  ) {
    return {
      value: Math.round((value / 1000000) * 100) / 100,
      unit: Mha,
    };
  } else if (
    (value < 0.01 && conversionUnit === null) ||
    conversionUnit === m2
  ) {
    return {
      value: Math.round(value * 10000 * 100) / 100,
      unit: m2,
    };
  } else {
    return {
      value: Math.round(value * 100) / 100,
      unit: ha,
    };
  }
};

/* CARBON DATA */

export const GtCO2e = "GtCO2e";
export const MtCO2e = "MtCO2e";
export const KtCO2e = "KtCO2e";
export const tCO2e = "tCO2e";

export const initialCarbonUnit = tCO2e;

const conversionFactors = {
  [GtCO2e]: 1e-9,
  [MtCO2e]: 1e-6,
  [KtCO2e]: 1e-3,
  [tCO2e]: 1,
};

function formatCO2eValue(tCO2eValue, conversionUnit = null) {
  // Determine the appropriate unit if not provided
  if (conversionUnit === null) {
    const absValue = Math.abs(tCO2eValue);

    if (absValue >= 1e9) {
      conversionUnit = GtCO2e;
    } else if (absValue >= 1e5) {
      conversionUnit = MtCO2e;
    } else if (absValue >= 1e2) {
      conversionUnit = KtCO2e;
    } else {
      conversionUnit = tCO2e;
    }
  }

  // Convert the value
  const convertedValue = tCO2eValue * conversionFactors[conversionUnit];
  return { value: convertedValue, unit: conversionUnit };
}

/* UNIT FUNTIONS */

export default function getUnitsForSingleChart(data, range) {
  const isArea = isAreaChart(data);
  const initialUnit = isArea ? initialAreaUnit : initialCarbonUnit;
  let tempUnits = initialUnit;

  data.forEach((v) => {
    v.values.forEach((val) => {
      const value = isArea
        ? formatAreaValue(val[initialUnit], null)
        : formatCO2eValue(val[initialUnit], null);

      // will determine the area unit based on the range of the chart
      if (isNumberInRange(val.year, range) && value.value !== 0) {
        tempUnits = value.unit;
      }
    });
  });

  return tempUnits;
}
