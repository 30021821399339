import { width, height, margin } from "../../constants";

export default function addTooltipFunctionality(
  svg,
  lines,
  xScale,
  yScale,
  setTooltip,
  unit,
  lineColors
) {
  // Add the vertical line that intercepts the data points
  const verticalLine = svg
    .append("line")
    .attr("class", "vertical-line")
    .attr("x1", -1)
    .attr("x2", -1)
    .attr("y1", 0)
    .attr("y2", height)
    .attr("stroke", "transparent") // Set the initial color to transparent
    .attr("stroke-width", 1)
    .style("stroke-dasharray", "3, 3");

  // Add rect, vertical line, and circles at each data point for each line
  lines.forEach((series, seriesIndex) => {
    const scaleBandwidth = xScale.bandwidth();
    const centerOfPoint = scaleBandwidth / 2;
    // Add a hover rect for each data point (year)
    svg
      .selectAll(`.hover-rect-${seriesIndex}`)
      .data(series.values)
      .enter()
      .append("rect")
      .attr("class", "hover-rect")
      .attr("x", (d) => xScale(d.year) + scaleBandwidth / 6)
      .attr("y", 0)
      .attr("width", scaleBandwidth / 1.5)
      .attr("height", height)
      .attr("fill", "transparent")
      .on("mouseover", (event, d) => {
        const x = xScale(d.year) + centerOfPoint;
        verticalLine
          .attr("x1", xScale(d.year) + centerOfPoint)
          .attr("x2", xScale(d.year) + centerOfPoint)
          .attr("y1", 0)
          .attr("y2", height)
          .attr("stroke", "white");

        // Change the opacity of all circles on the x-axis on hover
        svg
          .selectAll(".circle")
          .filter((circleData) => circleData.year === d.year)
          .attr("fill-opacity", 1);

        // Set the tooltip position
        const tooltipHeight = 100;
        const tooltipX = (year, x) => {
          return x <= (width + margin.left) / 2
            ? xScale(year) + scaleBandwidth + 40
            : xScale(year) - 120;
        };
        const tooltipY = (height - tooltipHeight) / 2;

        // Update the tooltip position
        setTooltip({
          visible: true,
          year: d.year,
          data: lines.map((line) => {
            return {
              name: line.name,
              value: line.values.find((point) => point.year === d.year)[unit],
              color: lineColors[lines.indexOf(line)],
            };
          }),
          unit: unit,
          x: tooltipX(d.year, x),
          y: tooltipY,
        });
      })
      .on("mouseout", (event, d) => {
        verticalLine.attr("stroke", "transparent");

        // Change the opacity of all circles on the x-axis on hover
        svg
          .selectAll(".circle")
          .filter((circleData) => circleData.year === d.year)
          .attr("fill-opacity", 0);
        // Hide the tooltip on mouseout
        setTooltip({
          visible: false,
          data: [],
          x: 0,
          y: 0,
        });
      });

    // Add transparent circles over each point
    svg
      .selectAll(`.circle-${seriesIndex}`)
      .data(series.values)
      .enter()
      .append("circle")
      .attr("class", "circle")
      .attr("cx", (d) => xScale(d.year) + centerOfPoint)
      .attr("cy", (d) => yScale(d[unit]))
      .attr("r", 5)
      .attr("fill", lineColors[seriesIndex])
      .attr("fill-opacity", 0); // Set initial opacity
  });
}
