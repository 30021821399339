import { useState } from "react";
import Button from "../../../../Modal/components/Button";
import UpArrow from "../../../../../../assets/up-arrow.svg";
import DownArrow from "../../../../../../assets/down-arrow.svg";

export default function ExpandableSection({ title, children, openModal, modalContent }) {
  const [expanded, setExpanded] = useState(false);

  const toggleSection = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="w-full h-fit lg:w-[410px] bg-white p-4 mb-2 last:mb-4 rounded-lg cursor-pointer">
      <div
        className="flex justify-between items-center"
        onClick={toggleSection}
      >
        <div className="inline-flex">
          <div className="font-bold text-lg">{title}</div>
          <Button
            openModal={openModal}
            modalContent={modalContent}
            icon={"i"}
          />
        </div>
        <button onClick={toggleSection}>
          {expanded ? (
            <img src={DownArrow} alt="open subsection" />
          ) : (
            <img src={UpArrow} alt="closed subsection" />
          )}
        </button>
      </div>
      <div
        className={`content ${
          expanded
            ? "opacity-100 max-h-auto transition-max-height duration-500 ease-in-out"
            : "opacity-0 max-h-0 overflow-hidden transition-max-height duration-500 ease-in-out"
        }`}
      >
        {children}
      </div>
    </div>
  );
};
