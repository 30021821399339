import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ParentItem from "./components/ParentItem";
import clsx from "clsx";
import * as styles from "../../styles";
import { filterInHierarchy } from "./helpers";

export default function SearchOptions({
  searchData,
  searchTerm,
  displayText,
  userFunctions,
}) {
  const [searchDataLoaded, setSearchDataLoaded] = useState(false); // search items are loaded

  const updatedFilteredData = filterInHierarchy(searchData, searchTerm);
  const noFilteredDataInSearch = updatedFilteredData.length && searchData;

  useEffect(() => {
    if (searchData) {
      setSearchDataLoaded(true);
    } else {
      console.error("Failed to fetch data.");
    }
  }, [searchData]);

  const onMouseOver = () => {
    // Enable scrolling when the mouse is over the resultsContainer
    document.getElementById("search-dropdown").style.overflowY = "auto";
  };

  const onMouseOut = () => {
    // Disable scrolling when the mouse leaves the resultsContainer
    document.getElementById("search-dropdown").style.overflowY = "hidden";
  };

  if (!searchTerm) return;

  return (
    <div className={styles.dropdownBodyContainer}>
      {!searchDataLoaded ? (
        <div className={styles.searchDropdownBody}>
          <Skeleton className="h-[50px] mb-2" count={6} />
        </div>
      ) : (
        <div>
          {noFilteredDataInSearch ? (
            <ul
              id="search-dropdown"
              className={searchDropdownStyle}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            >
              <ParentItem
                filteredData={updatedFilteredData}
                displayText={displayText}
                userFunctions={userFunctions}
              />
            </ul>
          ) : (
            <div className={"p-2 text-center"}>
              <p>
                The region you're searching for is not included in our dataset.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const searchDropdownStyle = clsx(
  "border mt-2 rounded-lg bg-white max-h-96 overflow-y-auto"
);
