import { useEffect, useState } from "react";
import { DownloadBtn, ProgressBar } from "../ChartSection/components";
import { useCurrentChart, useCurrentSelection } from "../../../../../../contexts";

export default function SubSection({
  description,
  breakdownTitle,
  breakdownSubtitle,
  colors,
  category,
}) {
  const { currentChart } = useCurrentChart();
  const { currentSelection } = useCurrentSelection();
  const [progressBar, setProgressBar] = useState(currentSelection.baselineData[category]);

  useEffect(() => {
    setProgressBar(currentSelection.baselineData[category]);
  }, [currentSelection.baselineData, category]);

  return (
    <div className="mb-1 max-w-[410px]">
      <p className="text-sm">{description}</p>
      <div className="w-full mx-auto mb-2">{currentChart.chart}</div>
      <div>
        <div className="font-semibold text-md">{breakdownTitle}</div>
        <div className="font-normal text-xs text-mediumGray mb-1">{breakdownSubtitle}</div>
        {Object.keys(progressBar).map((value, index) => (
          <div key={index}>
            <ProgressBar
              title={colors[value].display}
              percentage={progressBar[value].percent}
              unit={progressBar[value].unit}
              value={progressBar[value].value}
              color={colors[value].color}
            />
          </div>
        ))}
      </div>
      <DownloadBtn gid={currentSelection.gid} adminLevel={currentSelection.adminLevel} category={category} />
    </div>
  );
};
