import clsx from "clsx";
export default function MapTitle({ children, title }) {
  return (
    <div className={containerStyle}>
      <div className="justify-between items-center inline-flex">
        <div className="text-black text-sm">{title}</div>
        {children}
      </div>
    </div>
  );
}

const containerStyle = clsx("h-8 p-3 my-4 bg-white rounded-lg justify-center inline-flex");