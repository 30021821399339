import { height } from "../../constants";
import { scaleLinear } from "d3-scale";

const d3 = {
  scaleLinear,
};

export default function getYScale(minYValue = 0, maxYValue) {
  const range = maxYValue - minYValue;
  const minYValueIsNegative = minYValue < 0;
  
  const adjustedPaddingTop = minYValueIsNegative ? range * 0.05 : maxYValue + range * 0.05;
  const adjustedPaddingBottom = minYValue >= 0 ? range * 0.05 : adjustedPaddingTop;

  return d3
    .scaleLinear()
    .domain([
      Math.min(0, minYValue) - adjustedPaddingBottom, // Adjusted lower bound
      adjustedPaddingTop, // Adjusted upper bound
    ])
    .range([height, 0]);
}
