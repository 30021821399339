import svgIcon from "../../../../../../../../assets/download.svg";
const JSZip = require("jszip");

export default function DownloadBtn({ gid, adminLevel, category }) {
  async function downloadData(gid, category) {
    const apiPrefix = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;
    const csvEndpoint = `${apiPrefix}/jmrv/data_annual_csv?admin_level=${adminLevel}&gid_value=${gid}`;
    const pngEndpoint = `${apiPrefix}/jmrv/data_annual_png?admin_level=${adminLevel}&gid_value=${gid}&category_value=${category}`;

    // Fetch CSV data
    const csvResponse = await fetch(csvEndpoint);
    const csvData = await csvResponse.text();

    // Fetch PNG data
    const pngResponse = await fetch(pngEndpoint);
    const pngData = await pngResponse.blob();

    // Create a zip file
    const zip = new JSZip();
    zip.file(`${gid}_jmrv.csv`, csvData);
    zip.file(`${gid}_${category}_jmrv.png`, pngData);

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Create download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = `${gid}_${category}_jmrv_data.zip`;

    // Append the link to the body and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  }
  return (
    <button
      onClick={() => downloadData(gid, category)}
      className={`w-fit p-2 mt-2 rounded-lg inline-flex items-center cursor-pointer`}
    >
      <img src={svgIcon} />
      <div className="text-black text-sm pl-1">Download data</div>
    </button>
  );
}
